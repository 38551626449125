// Import required libraries and types
import { twMerge } from 'tailwind-merge';
import { clsx, type ClassValue } from 'clsx';

// Define a utility function for merging and combining CSS classes
export const cn = (...inputs: ClassValue[]) => {
  // Use clsx to conditionally join class names into a single string
  // clsx allows for conditional class application
  const combinedClasses = clsx(inputs);
  // Use twMerge to process the merged class string and apply Tailwind CSS classes
  return twMerge(combinedClasses);
};
